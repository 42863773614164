export interface ConfigItem {
    asset_name: string;
    options?: string;
    display_name?: string;
}

type RasterConfig = {
    keys: string[];
    config: Record<string, ConfigItem>;
}[];

const rasterConfig: RasterConfig = [
    {
        keys: ["L1D", "l1d", "l1d-sr", "L1D_SR"],
        config: {
            visual: {
                display_name: 'Visual',
                asset_name: "visual",
                options: "bidx=1&bidx=2&bidx=3",
            },            
            ndvi: {
                display_name: 'NDVI',
                asset_name: "analytic",
                options: "expression=(b4-b3)/(b4%2Bb3)&rescale=0,1&colormap_name=greens",
            },            
            false_color: {
                display_name: 'False color',
                asset_name: "analytic",
                options: "bidx=4&bidx=2&bidx=1&rescale=300,5000",
            },
            cloud: {
                display_name: 'Cloud Mask',
                asset_name: "cloud",
                options: "bidx=1",
            }
        }
    },
    {
        keys: ["GAIA"],
        config: {
            visual: {
                display_name: 'all',
                asset_name: "analytic",
                options: `nodata=32&expression=${encodeURIComponent(
                    '1*(b1/255)+2*(b2/255)+3*(b3/255)+4*(b4/255)+5*(b5/255)+6*(b6/255)+7*(b7/255)+8*(b8/255)+9*(b9/255)+10*(b10/255)+11*(b11/255)+12*(b12/255)+13*(b13/255)'
                )}&colormap=${encodeURIComponent(
                    JSON.stringify(
                        {"1":[1,100,1,255],"255":[255,187,34,255],"3":[255,255,76,255],"4":[240,150,255,255],"5":[250,1,1,255],"6":[180,180,180,255],"7":[240,240,240,255],"8":[1,100,200,255],"9":[1,150,160,255],"10":[1,207,117,255],"11":[250,230,160,255],"12":[125,125,125,255],"13":[255,255,255,255]}
                    ))}`,
                // ${encodeURIComponent(JSON.stringify({"0":[0,0,0,0],"1":[1,200,1,255],"2":[200,1,1,255],"3":[0,0,100,255]}))}`,
            },            
            cloud: {
                display_name: 'builtup sparce roads',
                asset_name: "analytic",
                options: `nodata=32&expression=${encodeURIComponent(
                    "5*(b5/255)+6*(b6/255)+12*(b12/255)"
                )}&colormap=${encodeURIComponent(
                    JSON.stringify(
                        {"1":[1,100,1,255],"255":[255,187,34,255],"3":[255,255,76,255],"4":[240,150,255,255],"5":[250,1,1,255],"6":[180,180,180,255],"7":[240,240,240,255],"8":[1,100,200,255],"9":[1,150,160,255],"10":[1,207,117,255],"11":[250,230,160,255],"12":[125,125,125,255],"13":[255,255,255,255]}
                    ))}`,

            }
        }        
    },
    {
        keys: ["QUICKVIEW_VISUAL","L1B"],
        config: {
            visual: {
                display_name: 'Visual',
                asset_name: "analytic",
                options: "bidx=1&bidx=2&bidx=3",
            },            
            cloud: {
                display_name: 'Cloud Mask',
                asset_name: "cloud",
                options: "bidx=1",
            }
        }
    },
    {
        keys: ["L1C"],
        config: {
            visual: {
                display_name: 'Preview',
                asset_name: "preview",                
            }
        }
    },
    {
        keys: ["L1A"],
        config: {
            analytic: {
                display_name: 'Analytic',
                asset_name: "preview",                
            },            
        }
    }
    ,
    {
        keys: ["L0"],
        config: {
            analytic: {
                display_name: 'Analytic',
                asset_name: "preview",                
            },            
        }
    }
];

// Lookup function to retrieve the configuration by key
export function getRasterConfigForProduct(level: string): Record<string, ConfigItem> | undefined {    
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry?.config;
}

export function getRasterConfigKeysForLevel(level: string): string[] | undefined {    
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry ? Object.keys(entry.config) : undefined;
}

export function getRasterDisplayNamesForLevel(level: string): { key: string; display_name: string }[] | undefined {
    const entry = rasterConfig.find(item => item.keys.includes(level));
    return entry
        ? Object.entries(entry.config).map(([key, item]) => ({
              key,
              display_name: item.display_name || item.asset_name
          }))
        : undefined;
}
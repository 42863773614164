import { observer } from "mobx-react-lite";
import archiveStore from "stores/archiveStore";
//@ts-ignore
import StacFields from '@radiantearth/stac-fields';
import { useItemContext } from './ItemContext'; // Import the context
import { Tooltip } from 'react-tooltip'
import { SmallTabContainer, MetadataTableWrapper, Actions, ActionIcon } from './infoTab.styles';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import DOMPurify from "dompurify"
import { addIdToProperties } from "views/archive/helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { useStores } from "config/hooks";
import { TabsContainer } from "components/tabscontainer";
import { TabObject } from "components/tabscontainer/tabscontainer";
import archiveResultsStore from "stores/archiveResultsStore";
import { mdiEye, mdiEyeOff, mdiCheckboxBlankOffOutline, mdiCheckboxMarkedOutline, mdiShare, mdiShareAll, mdiClipboard, mdiCopyright, mdiClipboardPlay, mdiClipboardTextOutline, mdiDownloadMultiple, mdiDownload, mdiEarthBox } from '@mdi/js';
import Icon from '@mdi/react';
import ItemCard from "./ItemCard";
import { LngLatBounds } from "maplibre-gl";
import { searchCollection } from "services/archiveService";
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import styles for react-virtualized
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from 'components/modal/confirmationmodal';

import { Button } from "components/button";

import ProgressBar from "./ProgressBar";
import { downloadFiles, downloadSingleFile, formatBytes, getAllAssetDetails, getSelectedAssetsInfo } from "./downloadHelpers"
import { width } from "@mui/system";
import { abort } from "process";
import { setTime } from "react-datepicker/dist/date_utils";

const handleViewMetadata = (id: string) => {
    console.log('View metadata for item with ID:', id);
    archiveStore.setActiveMetadataItem(id);
};

interface ProductInfoProps {
    product: string;
    itemsPerPage?: number;
}

export const ProductInfo = observer((props: ProductInfoProps) => {

    const abortControllerRef = useRef<AbortController | null>(null); // Store AbortController in ref

    const {
        rootStore: { userStore, notificationStore }
    } = useStores();

    const downloadFormRef = useRef<HTMLFormElement>(null);
    const [downloadAssets, setDownloadAssets] = useState<any | undefined>(undefined);
    const [progress, setProgress] = useState(0);
    const [totalSize, setTotalSize] = useState<number | undefined>(undefined);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
    const [downloadButtonEnabled, setDownloadButtonEnabled] = useState<boolean>(false);

    const items = archiveStore.activeProductData?.features;

    if (!items) {
        return (null);
    }

    const handleTitleClick = (id: string) => {
        const item = archiveStore.activeProductData?.features.filter(feature => feature.id == id)[0]
        const bbox = item?.bbox;

        let bounds: LngLatBounds;

        if (!!bbox) {
            bounds = new LngLatBounds(
                [bbox[0], bbox[1]], // southwest corner
                [bbox[2], bbox[3]]  // northeast corner
            );
            archiveStore.maplibreMap?.fitBounds(bounds, {
                essential: true,
                duration: 750,
                padding: 200,
            })
        }
    };

    const { areAllSelected, areAllHidden, selectAll, unselectAll, hideAll, unhideAll, selectedItems } = useItemContext();

    const rowRenderer = ({ index, key, style }: ListRowProps) => {
        const feature = items[index];
        if (!feature) return null;
        return (
            <div key={key} style={style}>
                <ItemCard
                    feature={feature}
                    ref={(el) => archiveResultsStore.setCardRef(feature.id, el)}
                    onViewMetadata={handleViewMetadata}
                    onTitleClick={handleTitleClick}
                />
            </div>
        );
    };

    const handleOpenDownloadModalClick = async () => {

        const filteredFeatures = archiveStore.activeProductData?.features.filter(feature =>
            selectedItems.has(feature.id)
        );

        if (!filteredFeatures || filteredFeatures.length === 0) return;

        loadingNotificationStore.setText('Loading assets information...');
        loadingNotificationStore.setLoading(true);

        const assetDetails = await getAllAssetDetails(filteredFeatures);
        loadingNotificationStore.setLoading(false);

        setDownloadAssets(assetDetails);
        setShowDownloadModal(true);
    };


    // Handler for the download button click
    const handleDownload = (resetLoading?: () => void) => {
        if (resetLoading) resetLoading();
        setDownloadButtonEnabled(false);
        if (downloadFormRef.current) {
            downloadFormRef.current.requestSubmit();
        }
        //if (resetLoading) resetLoading();
    };

    const handleCancel = () => {
        if (downloadInProgress) {
            abortControllerRef.current?.abort();
        } else { // Its just closing before downloading
            setShowDownloadModal(false);
        }
    }

    // Handler for form submission
    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        setDownloadInProgress(true);

        const formData = new FormData(event.currentTarget); // Get form data

        const assetsNames = Object.keys(Object.fromEntries(formData.entries()));

        let selectedAssetsInfo = getSelectedAssetsInfo(downloadAssets, assetsNames);

        let extraFiles: { name: string, input: string | Blob }[] = [];

        if (assetsNames.includes("stac_metadata")) {
            const filteredFeatures = archiveStore.activeProductData?.features.filter(feature =>
                selectedItems.has(feature.id)
            );

            filteredFeatures?.forEach(feature => {
                const metadata = JSON.stringify(feature, null, 2)
                const blob = new Blob([metadata], { type: 'application/json' });

                extraFiles.push({ name: `${feature.id}.json`, input: blob });
            }
            )
        }


        // Format the date in YYYYMMDDHHMMSS format
        const now = new Date();

        const formattedDate =
            now.getFullYear().toString() +
            (now.getMonth() + 1).toString().padStart(2, "0") + // Months are zero-based, so add 1
            now.getDate().toString().padStart(2, "0") +
            now.getHours().toString().padStart(2, "0") +
            now.getMinutes().toString().padStart(2, "0") +
            now.getSeconds().toString().padStart(2, "0");

        const fileName = `aleph_${formattedDate}.zip`;

        abortControllerRef.current = new AbortController(); // Set the controller to ref

        if (abortControllerRef.current) {
            downloadFiles(
                selectedAssetsInfo.hrefs,
                selectedAssetsInfo.names,
                extraFiles,
                fileName,
                (progress: number) => { setProgress(progress) },
                () => {
                    setDownloadInProgress(false);
                    setTimeout(() => {
                        setShowDownloadModal(false);
                        setTotalSize(undefined);
                    }, 1000);
                },
                (error: any) => {
                    if (abortControllerRef.current?.signal.aborted) {
                        console.log("The download has been cancelled by the user")
                        setDownloadInProgress(false);
                        setProgress(0);
                        setDownloadButtonEnabled(true);
                    } else {
                        console.log("Download error", error)
                        /*
                        notificationStore.add({ description: error });
                        loadingNotificationStore.setErrorMessage(
                            `There was an error during the download: ${error}`
                        );
                        */
                    }
                },
                abortControllerRef.current
            )
        }

    };

    const handleFormChanged = (event: React.FormEvent<HTMLFormElement>) => {

        const formData = new FormData(event.currentTarget); // Get form data

        const assetsNames = Object.keys(Object.fromEntries(formData.entries()));

        let selectedAssetsInfo = getSelectedAssetsInfo(downloadAssets, assetsNames);

        setTotalSize(selectedAssetsInfo.totalSize)

        setDownloadButtonEnabled(assetsNames.length > 0)
    }

    return (
        <>
            {!!archiveStore.activeProductData && archiveStore.activeProductData.features.length > 0 ? (
                <>
                    <div style={{ width: '100%', padding: '5px' }}>
                        <Tooltip id="header-tooltip" />
                        <Actions>
                            <div style={{ width: "100%" }}>
                                <div id={"firstrow"} style={{ display: 'flex', alignItems: 'center', width: "100%", paddingBottom: "5px" }}>

                                    <div style={{ width: "70%" }}>
                                        <p>Total items: {archiveStore.activeProductData.features.length} </p>
                                    </div>

                                    <div style={{ flex: "1", marginLeft: 'auto', display: 'flex', alignItems: 'flex-end', width: "100%" }}>
                                        <Actions>
                                            <Tooltip id="item-tooltip" />
                                            <ActionIcon
                                                onClick={unhideAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Show all">
                                                <Icon path={mdiEye} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={hideAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Hide all">
                                                <Icon path={mdiEyeOff} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={selectAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Select all">
                                                <Icon path={mdiCheckboxMarkedOutline} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={unselectAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Unselect all">
                                                <Icon path={mdiCheckboxBlankOffOutline} size={0.7} />
                                            </ActionIcon>
                                        </Actions>
                                    </div>
                                </div>
                                <div id={"second"} style={{ display: 'flex', alignItems: 'center', width: "100%" }}>

                                    <div style={{ width: "70%", display: 'flex', alignItems: 'flex-end', }}>
                                        <p>Selected:  </p>
                                    </div>

                                    <div style={{ marginLeft: 'auto', padding: "10px 0" }}>
                                        <Actions style={{ width: "100%" }}>

                                            <Button
                                                className='verysmall'
                                                text={`Download (${selectedItems.size.toFixed(0)})`}
                                                disabled={selectedItems.size == 0 || downloadInProgress}
                                                onClick={handleOpenDownloadModalClick}
                                                icon=<Icon path={mdiDownloadMultiple} />
                                            />


                                        </Actions>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: "5px" }}>
                                    GSD: {archiveStore.activeProductData.features[0].properties["gsd"] ? archiveStore.activeProductData.features[0].properties["gsd"].toFixed(1) + "m" : "N/A"}
                                </div>
                            </div>
                        </Actions>
                    </div>
                    <div style={{ height: '100%', width: '100%' }}> {/* Set appropriate height for your list */}
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={null} raw
                                    width={width}
                                    height={height}
                                    rowCount={items.length}
                                    rowHeight={100} // Set row height as per your design
                                    rowRenderer={rowRenderer}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </>
            ) : (
                <p>No items found for this one</p>
            )}

            {showDownloadModal &&
                <ConfirmationModal
                    isShown={showDownloadModal}
                    title={'Select assets to download'}
                    acceptText={`Download`}
                    cancelText={!!downloadInProgress ? 'Cancel download' : 'Close'}
                    onCancel={handleCancel}
                    onClose={() => { }}
                    onConfirm={(resetLoading) => handleDownload(resetLoading)}
                    confirmButtonDisabled={!downloadButtonEnabled}
                >
                    {downloadAssets && Object.keys(downloadAssets).length > 0 ?
                        <>
                            <form style={{ width: "400px" }} ref={downloadFormRef} onSubmit={handleFormSubmit} onChange={handleFormChanged}>
                                <fieldset style={{ padding: "10px" }}>

                                    <h3 style={{ padding: "0 0 10px 0" }}>Assets:</h3>

                                    {Object.keys(downloadAssets).map((key) => (
                                        <div key={key} style={{ padding: "3px" }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={key}
                                                />
                                                {key} ({downloadAssets[key].length} items, {formatBytes(downloadAssets[key].reduce((sum: number, { filesize }: { filesize: number; }) => sum + filesize, 0), 0)})
                                            </label>
                                        </div>
                                    ))}

                                    <h3 style={{ padding: "10px 0 10px 0" }}>Extras:</h3>
                                    <div style={{ padding: "3px" }}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="stac_metadata"
                                            />
                                            STAC Metadata as JSON ({selectedItems.size})
                                        </label>
                                    </div>
                                </fieldset>
                            </form>
                            <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>

                                <h1 style={{ marginTop: "20px" }}>{!!totalSize ? "Total download size: " + formatBytes(totalSize, 0) : 'Select assets to calculate total size'}</h1>

                                <div style={{ width: "100%", padding: "10px" }}>
                                    {!!downloadInProgress ?
                                        <>
                                            <h1 style={{ padding: "10px 0" }}>Downloading</h1>
                                            <ProgressBar
                                                total={totalSize}
                                                progress={progress}
                                            />
                                            <div style={{ fontSize: "14px", padding: "10px 0" }}>Download in progress...</div>
                                        </>

                                        : null
                                    }
                                </div>
                            </div>

                        </>
                        : null}
                </ConfirmationModal>
            }
            {(!!downloadAssets && Object.keys(downloadAssets).length > 0)
            }
        </>

    );
});

export const InfoTab = observer(() => {
    const { unselectAll } = useItemContext();

    const {
        rootStore: { userStore, notificationStore }
    } = useStores();

    const [productTabs, setProductTabs] = useState<TabObject[]>([
        {
            label: 'L0',
            content: <ProductInfo product="L0" />,
            enabled: false,
            tooltip: 'Raw'
        },
        {
            label: 'L1A',
            content: <ProductInfo product="L1A" />,
            enabled: false,
            tooltip: 'Corrected raw'
        },
        {
            label: 'L1B',
            content: <ProductInfo product="L1B" />,
            enabled: false,
            tooltip: "Basic Geocorrected"
        },
        {
            label: 'L1C',
            content: <ProductInfo product="L1C" />,
            enabled: false,
            tooltip: 'Ortho-Ready'
        },
        {
            label: 'L1D',
            content: <ProductInfo product="L1D" />,
            enabled: false,
            tooltip: 'Orthorectified'
        },
        {
            label: 'L1DSR',
            content: <ProductInfo product="L1D_SR" />,
            enabled: false,
            tooltip: 'Ortho. Super resolved'
        },
        ...(userStore.hasGaiaPermission() ? [{
            label: 'LU',
            content: <ProductInfo product="LU" />,
            enabled: false,
            tooltip: 'AI Resuls'
        }] : []),
    ]);

    const [selectedTab, setSelectedTab] = useState<string | undefined>(productTabs[0].label);

    const updateTabEnabled = (targetLabel: string) => {
        setProductTabs(prevTabs =>
            prevTabs.map(tab =>
                tab.label === targetLabel
                    ? {
                        ...tab,
                        enabled: true,
                    }
                    : tab
            )
        );
    };

    const isTabEnabled = (label: string): boolean => {
        const tab = productTabs.find(tab => tab.label === label);
        return tab ? tab.enabled : false;
    };

    const handleTabChange = (tab: string) => {
        setSelectedTab(tab);
        unselectAll();
        if (archiveStore.activeCaptureData) {
            archiveStore.setActiveProductData(archiveStore.activeCaptureData[tab]);
        }
    };

    const getTokenFromStore = useCallback(async () => {
        const token = await userStore.auth0Client?.getTokenSilently();
        return token;
    }, [userStore]);

    const showList: Array<keyof StacItemProperties> = [
        "datetime",
        "platform",
        "eo:cloud_cover",
        "satl:shadow_cover",
        "view:off_nadir",
        "view:sun_elevation",
        "view:sun_azimuth",
    ]

    const productNames: { [key: string]: string } = {
        "L1A": "L1A",
        "L0": "L0",
        "QUICKVIEW_VISUAL": "L1B",
        "QUICKVIEW_TOA": "L1B",
        "L1C": "L1C",
        "L1D": "L1D",
        "L1D_SR": "L1DSR",
        "L1D-SR": "L1DSR",
        "GAIA": "LU"
    };


    const searchCollections: string[] = [
        "l0",
        "l1a",
        "quickview-visual",
        "l1c-benchmark",
        "l1d",
        "l1d-sr",
        ...(userStore.hasGaiaPermission() ? ["gaia"] : []),
    ]
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`/archive?outcome_id=${archiveStore.activeCapture?.properties['satl:outcome_id']}`);
    }, []);

    useEffect(() => {

        const getRelatedProducts = async () => {
            const token = await getTokenFromStore();

            if (archiveStore.activeCapture) {
                loadingNotificationStore.setText('Loading capture information...');
                loadingNotificationStore.setLoading(true);

                try {

                    const results = await Promise.all(searchCollections.map(async (collection) => {

                        if (archiveStore.activeCapture) {
                            const outcome_id_filter: {} = {
                                op: 'and',
                                args: [
                                    {
                                        op: '=',
                                        args: [
                                            { property: 'satl:outcome_id' }, archiveStore.activeCapture.properties["satl:outcome_id"]
                                        ]
                                    }
                                ]
                            };

                            const searchCollectionParams = {
                                startDate: undefined,
                                endDate: undefined,
                                collections: [collection],
                                limitSearchAmount: '1000',
                                polygon: undefined,
                                filter: outcome_id_filter,
                            };

                            // Return the promise from searchCollection
                            return searchCollection(token, searchCollectionParams);
                        }
                    }));

                    // Iterate over the resolved results
                    results.forEach((data, index) => {
                        if (data && data.features) {
                            if (data.features.length > 0) {
                                const product: string = productNames[data.features[0].properties["satl:product_name"].toUpperCase()]

                                updateTabEnabled(product)

                                const processedFeatures = addIdToProperties(data);
                                archiveStore.setActiveCaptureData(product, processedFeatures)
                            }
                        } else {
                            console.log('No features found in this result.');
                        }
                        loadingNotificationStore.setLoading(false);
                    });


                } catch (error) {
                    loadingNotificationStore.setLoading(false);
                    notificationStore.add({ description: `There was an error in your search:` });
                    console.error(error);
                }
            }

        }
        getRelatedProducts();

    }, [getTokenFromStore, archiveStore.activeCapture]);

    // Pre defined order
    useEffect(() => {
        if (isTabEnabled("L1DSR")) {
            handleTabChange("L1DSR")
        } else if (isTabEnabled("L1D")) {
            handleTabChange("L1D")
        } else if (isTabEnabled("L1B")) {
            handleTabChange("L1B")
        } else if (isTabEnabled("L1C")) {
            handleTabChange("L1C")
        }
    }, [productTabs]);


    const rows = archiveStore.activeCapture?.properties
        ? showList.map((key) => {
            if (archiveStore.activeCapture?.properties[key] !== undefined) {
                const value = archiveStore.activeCapture?.properties[key];

                StacFields.Registry.addMetadataField('satl:shadow_cover', {
                    label: "Shadow",
                    formatter: (value: number) => value + " %"
                });

                let formatted = "";
                if (typeof value === 'number') {
                    formatted = StacFields.format(value.toFixed(1), key, archiveStore.activeCapture);
                } else {
                    formatted = StacFields.format(value, key, archiveStore.activeCapture);
                }
                const label = StacFields.label(key);

                return (
                    <tr key={key}>
                        <td className="property">
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }} />
                        </td>
                        <td className="value">
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatted) }} />
                        </td>
                    </tr>
                );
            }
            return null;
        })
        : "";

    const handleTabHover = (tab: string, event: any) => {
        if (!isTabEnabled(tab)) {

            const reversedDict: Record<string, string> = {};

            for (const [key, value] of Object.entries(productNames)) {
                reversedDict[value] = key;
            }

            const collection: string = reversedDict[tab.toUpperCase()]?.toLowerCase()

            if (userStore.scope && !userStore.scope.includes("stac_collection:" + tab)) {
                //console.log("the user has it but there is no data" + collection)
            } else {
                //console.log("the user doesnt have the scope" + collection)
            }

        }
    }

    const outcomeId = archiveStore.activeCapture?.properties["satl:outcome_id"]
    const resultThumbnails = archiveStore.searchResultsThumbnail;
    const fullThumbnailHref = resultThumbnails?.features.find(item => item.properties["satl:outcome_id"] === outcomeId)?.assets.analytic.href

    return (
        <>
            {!!archiveStore.activeCapture && archiveStore.activeCapture?.properties ? (
                <MetadataTableWrapper>
                    <table>
                        <Tooltip id="actions-tooltip" />
                        <thead>
                            <tr>
                                <th colSpan={2}>{archiveStore.activeCapture?.properties["satl:outcome_id"]}</th>
                            </tr>
                            <tr>
                                <th>
                                    <div style={{ display: "flex" }}>
                                        <ActionIcon
                                            onClick={() => { navigator.clipboard.writeText(window.location.href) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Copy shareable url to clipboard">
                                            <Icon path={mdiShareAll} size={0.7} color={"black"} />
                                        </ActionIcon>
                                        <ActionIcon
                                            onClick={() => { if (archiveStore.activeCapture?.properties["satl:outcome_id"]) navigator.clipboard.writeText(archiveStore.activeCapture?.properties["satl:outcome_id"]) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Copy outcome id to clipboard">
                                            <Icon path={mdiClipboardTextOutline} size={0.7} color={"black"} />
                                        </ActionIcon>

                                        {fullThumbnailHref && <ActionIcon
                                            onClick={() => { downloadSingleFile(fullThumbnailHref) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Download geolocated preview">
                                            <Icon path={mdiEarthBox} size={0.7} color={"black"} />
                                        </ActionIcon>
                                        }
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>

                </MetadataTableWrapper>
            ) : (
                <p>Please select a capture first</p>
            )}


            <SmallTabContainer>
                <TabsContainer
                    tabs={productTabs} selectedTab={selectedTab} setSelectedTab={handleTabChange}
                    onTabHover={handleTabHover} />
            </SmallTabContainer>
        </>

    )

});

InfoTab.displayName = 'Details';

import React from 'react'
import { BaseModal } from '../basemodal'

import { LayerItem } from 'components/layers/layers.store'

import {
  Modal,
  TaskInfo,
  TaskTitle,
  TaskCoordinates,
  TaskContent,
  TaskRow,
  SpanBold,
  RowTitle
} from './modalmetadata.styles'

import { dateFormater, coordinatesFormater } from 'config/helpers'

interface Props {
  className?: string
  isShown: boolean
  layer: LayerItem
  onClose: () => void
}

export const ModalMetadata = ({ className, isShown, layer, onClose }: Props) => {
  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <Modal className={className}>
        <TaskInfo>
          <TaskTitle>{layer.metadata.scene_id}</TaskTitle>
          {layer.metadata.footprint.type === 'Point' ? (
            <TaskCoordinates>
              {coordinatesFormater([
                layer.metadata.footprint.coordinates[1],
                layer.metadata.footprint.coordinates[0]
              ])}
            </TaskCoordinates>
          ) : null}
        </TaskInfo>
        <TaskContent>
          <TaskRow className="no-border">
            <RowTitle>Metadata</RowTitle>
          </TaskRow>
          {layer.metadata.metadata.aoi_tag ? (
            <TaskRow>
              <SpanBold>AOI tag:</SpanBold> {layer.metadata.metadata.aoi_tag}
            </TaskRow>
          ) : null}

          {layer.metadata.productname ? (
            <TaskRow>
              <SpanBold>Product name:</SpanBold> {layer.metadata.productname}
            </TaskRow>
          ) : null}

          {layer.metadata.timestamp ? (
            <TaskRow>
              <SpanBold>Capture date:</SpanBold> {dateFormater(layer.metadata.timestamp)}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.sun_elevation ? (
            <TaskRow>
              <SpanBold>Sun elevation:</SpanBold> {layer.metadata.metadata.sun_elevation.toFixed(2)}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.planned_sun_elevation ? (
            <TaskRow>
              <SpanBold>Sun elevation: </SpanBold>
              {layer.metadata.metadata.planned_sun_elevation.toFixed(2)}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.offnadir_deg ? (
            <TaskRow>
              <SpanBold>Off Nadir Angle: </SpanBold>
              {layer.metadata.metadata.offnadir_deg.toFixed(2)}
            </TaskRow>
          ) : null}

          {!layer.metadata.metadata.offnadir_deg && layer.metadata.metadata.location_requested.offnadir_deg ? (
            <TaskRow>
              <SpanBold>Off Nadir Angle: </SpanBold>
              {layer.metadata.metadata.location_requested.offnadir_deg.toFixed(2)}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.cloud_coverage !== undefined ? (
            <TaskRow>
              <SpanBold>Cloud coverage:</SpanBold> {layer.metadata.metadata.cloud_coverage}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.productname ? (
            <TaskRow>
              <SpanBold>Product: </SpanBold> {layer.metadata.metadata.productname}
            </TaskRow>
          ) : null}

          <TaskRow>
            <SpanBold>Payload: </SpanBold>
            {layer.metadata.metadata.camera === "micro" ? 'Multispectral' : 'Hyperspectral'}
          </TaskRow>

          {layer.metadata.metadata.gsd ? (
            <TaskRow>
              <SpanBold>GSD: </SpanBold> {Math.round(layer.metadata.metadata.gsd * 100) / 100}
            </TaskRow>
          ) : null}

          {layer.metadata.metadata.area_km2 ? (
            <TaskRow>
              <SpanBold>Coverage (Km2): </SpanBold> {Math.round(layer.metadata.metadata.area_km2 * 10) / 10}
            </TaskRow>
          ) : null}
        </TaskContent>
      </Modal>
    </BaseModal>
  );
};

ModalMetadata.displayName = 'ModalMetadata';

import React, { useEffect, useRef, useState } from 'react';

import Map, { Marker, ScaleControl, GeolocateControl, FullscreenControl, NavigationControl, MapRef } from 'react-map-gl/maplibre';
import { InfoControl } from '../../components/mapControls/InfoControl';
import { GeocoderControl } from '../../components/mapControls/geocoderControl';
import Typography from '@mui/material/Typography';

import { format, addMinutes, parseISO } from 'date-fns'

import { observer } from 'mobx-react-lite';

import { Layout } from 'components/layout';
import { ProjectModal } from 'components/modal/projectmodal';


import { LoadingIcon, } from 'components/icons';


import { Opportunity, Project } from 'typings';
import { isPointInsidePolygon, parseCoordinates } from 'config/helpers';
import { useMergeState, useStores } from 'config/hooks';

import { publishTask as publishTaskService } from 'services';

import { Button as MuiButton, LinearProgress, Autocomplete, TextField, Grid2 as Grid, TableSortLabel, Grid2, makeStyles, AccordionSummary, AccordionDetails, Link, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { SatelliteAltOutlined } from '@mui/icons-material';
import { AnalysisResult, canAddOrder, getAnalysis, getOpportunities, StatusEnum } from 'services/analysisService';

import Icon from '@mdi/react';
import { mdiWeatherSunny, mdiWeatherSunsetUp } from '@mdi/js';

import {
  Wrapper,
  Sidebar,
  FormTitle,
  FormSubtitle,
  FormText,
  Form,
  FormGroup,
  LoadingWrapper,
  MapContainer,
  DateRange
} from './createTask.styles';
import { HeadCell, OppsTable } from './oppsTable';
import { Input } from 'components/input';

import { mdiTune } from '@mdi/js';

import { Collapsable } from 'components/collapsable';
import { RangeSlider } from 'components/rangeslider';

import { CollectionWindowType, ProductConfig, productList, RangeType, TargetType } from './productConfig'
import { addDurationToDate } from './helpers';
import { TaskingOrderConfirmModal } from 'components/modal/taskingOrderConfirmModal';
import { useNavigate } from 'react-router-dom';
import { CloudForecastRequest, CloudForecastResponse, getWeatherForecast } from 'services/weatherService';

interface AnalysisStatus {
  hash: string | null;
  status: 'idle' | 'pending' | 'finished' | 'error' | 'empty';
}



export const CreateTask = observer(() => {

  // Setup stores
  const {
    rootStore: { taskStore, notificationStore, userStore }
  } = useStores();

  React.useEffect(() => {
    taskStore.init();
  }, [taskStore]);

  const navigate = useNavigate();



  // =============================== Modal states ===================================================  
  const [showModal, setShowModal] = useMergeState({
    newProject: false,
    preview: false,
    info: false
  });

  // ==================================== Analysis data management ==========================================
  const initialAnalysisData: AnalysisStatus = localStorage.getItem('taskingAnalysisData')
    ? JSON.parse(localStorage.getItem('taskingAnalysisData') || '{}')
    : { hash: null, status: 'idle'};

  const [analysisData, setAnalysisData] = useState<AnalysisStatus>(initialAnalysisData);

  // Update localStorage whenever analysisData changes
  useEffect(() => {
    localStorage.setItem('taskingAnalysisData', JSON.stringify(analysisData));
  }, [analysisData]);

  // =============================== Opportunities tables ============================================  

  const initialOppsData: Opportunity[] = localStorage.getItem('oppsData')
  ? JSON.parse(localStorage.getItem('oppsData') || '[]')
  : [];
  const [oppsData, setOppsData] = useState<Opportunity[]>(initialOppsData);
  const [standardIsFeasible, setStandardIsFeasible] = useState<boolean>(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | undefined>(undefined);

  useEffect(() => {
    localStorage.setItem('oppsData', JSON.stringify(oppsData));
  }, [oppsData]);

  const headCells: HeadCell[] = [
    {
      id: 'date',
      tooltip: "Date",
      label: 'Date UTC',
      colspan: 2,
    },
    {
      id: 'satellite',
      tooltip: <div>Mark V: 70cm native 50cm SR <br />  Mark IV 99cm native / 70 cm SR</div>,
      label: 'Satellite',
    },
    {
      id: 'ona',
      tooltip: "Off nadir angle",
      label: 'ONA',
    },
    {
      id: 'sun',
      tooltip: "Sun elevation at the target",
      label: 'Sun',
    },
    {
      id: 'cloud',
      tooltip: "Forecasted cloud cover for location and date",
      label: 'Clouds',
    },
  ]

  // ===================== Form management ===================================================

  interface FormData {
    task_name?: string;
    project_name?: string;
    start?: Date;
    end?: Date;
    target?: { type: string, coordinates: number[]; }
    ona_range?: { minValue: number, maxValue: number };
    sun_elevation_range?: { minValue: number, maxValue: number };
    selected_product?: keyof typeof productList;
  }


  const emptyForm: FormData = {
    task_name: '',
    project_name: '',
    start: undefined,
    end: undefined,
    target: { type: 'Point', coordinates: [] },
    ona_range: { minValue: 0, maxValue: 25 },
    sun_elevation_range: { minValue: 15, maxValue: 90 },
    selected_product: undefined
  }

  const initialFormData: FormData = localStorage.getItem('taskingForm')
    ? JSON.parse(localStorage.getItem('taskingForm') || '{}')
    : emptyForm;

  const [formData, setFormData] = useMergeState<FormData>(initialFormData)
  //const [formData, setFormData] = useMergeState<FormData>(emptyForm)

  const [validInput, setValidInput] = useMergeState<{ [key in keyof FormData]?: boolean }>({ // Form Validation States
    task_name: true,
    project_name: true,
    start: true,
    end: true,
    target: true,
    ona_range: true,
    sun_elevation_range: true,
    selected_product: true,
  });

  useEffect(() => {
    localStorage.setItem('taskingForm', JSON.stringify(formData));
  }, [formData]);


  // ========================= Coordinates state and helpers ============================
  const inputRef = useRef<HTMLInputElement>(null);

  // Controlled state for coord input to allow keyboard or map input
  const [coordsInput, setCoordsInput] = useState<string | undefined>(formData.target?.coordinates && formData.target.coordinates.length > 0 ? `${formData.target.coordinates[0].toFixed(5)}, ${formData.target.coordinates[1].toFixed(5)}` : undefined)

  // Validate if Target is inside Geofence
  const isTargetValid = (latLng: number[]) => {
    const geoFenceCoordinates = userStore.detachedRegeneratedGeofence;
    return (
      !geoFenceCoordinates ||
      (geoFenceCoordinates && isPointInsidePolygon(latLng, geoFenceCoordinates))
    );
  };

  // Set Point
  const setPoint = (lat: number, lng: number) => {
    setCoordsInput(`${lat.toFixed(5)}, ${lng.toFixed(5)}`)
    setFormData({
      target: { type: 'Point', coordinates: [lat, lng] }
    })
  };

  // ========================= Map config ============================  
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [moveEvent, setMoveEvent] = useState<number[] | null>(null);

  useEffect(() => {
    if (mapRef) {
      const map = mapRef.getMap();
      const createMarker = async () => {
        if (!map.hasImage('poi-marker')) {
          const image = await map?.loadImage('pin.png');
          if (!!image) map?.addImage("poi-marker", image.data);
        }
      }
      createMarker();
    }
  }, [mapRef]);

  // ========================= Task placement ============================  

  const [submittingTask, setSubmittingTask] = React.useState(false);
  const [triggerNavigation, setTriggerNavigation] = React.useState(false);

  const buildTaskStart = (originalStart: string) => {
    return format(addMinutes(parseISO(originalStart), -45), "yyyy-MM-dd'T'HH:mm:ss'Z'")
  }

  const buildTaskEnd = (originalEnd: string) => {
    return format(addMinutes(parseISO(originalEnd), 45), "yyyy-MM-dd'T'HH:mm:ss'Z'")
  }

  const buildOnaRange = (minOna: number, maxOna: number) => {
    return {
      minValue: Math.max(0, minOna - 2),
      maxValue: Math.min(90, maxOna + 2)
    }
  }

  const buildSunAngleRange = (minSunAngle: number, maxSunAngle: number) => {
    return {
      minValue: Math.max(-90, minSunAngle - 5),
      maxValue: Math.min(90, maxSunAngle + 5)
    }
  }

  const requestTask = async () => {

    console.log(formData)

    if (submittingTask) return;
    if (!formData.target?.coordinates) return;
    if (!formData.selected_product) return;

    // XXX: Be careful, !minValue will evaluate to true if minValue is 0, thats why null and undefined have to be checked individually
    if (formData.ona_range?.minValue === null || formData.ona_range?.minValue === undefined ||
      formData.ona_range?.maxValue === null || formData.ona_range?.maxValue === undefined ||
      formData.sun_elevation_range?.minValue === null || formData.sun_elevation_range?.minValue === undefined ||
      formData.sun_elevation_range?.maxValue === null || formData.sun_elevation_range?.maxValue === undefined) {
      return;
    }
  
    setSubmittingTask(true);
    let body = {};

    if (formData.selected_product === "rush") {
      if (!selectedOpportunity?.start || !selectedOpportunity?.end) return;

      const onaRange = buildOnaRange(
        selectedOpportunity.metadata.min_absolute_off_nadir,
        selectedOpportunity.metadata.max_absolute_off_nadir
      )
      const sunRange = buildSunAngleRange(
        selectedOpportunity.metadata.min_sun_elevation,
        selectedOpportunity.metadata.max_sun_elevation
      )
      body = {
        start: buildTaskStart(selectedOpportunity.start),
        end: buildTaskEnd(selectedOpportunity.end),
        task_name: formData.task_name,
        project_name: formData.project_name,
        product: productList[formData.selected_product]?.tasks.capture.product_id,
        max_captures: productList[formData.selected_product]?.tasks.capture.max_captures,
        max_simultaneous_captures: productList[formData.selected_product]?.tasks.capture.max_simultaneous_captures,        
        target: {
          type: 'Point',
          coordinates: [formData.target?.coordinates[1], formData.target?.coordinates[0]]
        },
        min_ona: onaRange.minValue,
        max_ona: onaRange.maxValue,
        min_sun_elevation: sunRange.minValue,
        max_sun_elevation: sunRange.maxValue,
        lock_in: false,
        priority: productList[formData.selected_product]?.tasks.capture.priority
      };
    } else if (formData.selected_product === "standard") {
      body = {
        start: formData.start,
        end: formData.end,
        task_name: formData.task_name,
        project_name: formData.project_name,
        product: productList[formData.selected_product]?.tasks.capture.product_id,
        max_captures: productList[formData.selected_product]?.tasks.capture.max_captures,
        max_simultaneous_captures: productList[formData.selected_product]?.tasks.capture.max_simultaneous_captures,        
        target: {
          type: 'Point',
          coordinates: [formData.target?.coordinates[1], formData.target?.coordinates[0]]
        },
        min_ona: formData.ona_range?.minValue,
        max_ona: formData.ona_range?.maxValue,
        min_sun_elevation: formData.sun_elevation_range?.minValue,
        max_sun_elevation: formData.sun_elevation_range?.maxValue,
        lock_in: false,
        priority: productList[formData.selected_product]?.tasks.capture.priority
      };
    }

    const token = await userStore.auth0Client?.getTokenSilently();

    publishTaskService(
      body,
      token as string,
      r => {
        if (r.data.task_id) {
          taskStore.addTask(r.data);
          notificationStore.add({
            description: `The task has been created successfully`
          });
          setSubmittingTask(false);
          setShowModal({ preview: false });
          setAnalysisData({ hash: null, status: 'idle'});
          setFormData(emptyForm);
          setTriggerNavigation(true); // Add a new state variable to trigger navigation          
        } else {
          errorHandler(r.data);
          setSubmittingTask(false);
          setShowModal({ preview: false });
        }
      },
      r => {
        errorHandler(r.data);
        setSubmittingTask(false);
        setShowModal({ preview: false });
      }
    );
  };

  // BlackWing Error Handler
  const errorHandler = (data: any) => {
    for (const [key, errors] of Object.entries(data)) {
      if (key === 'status_details' || key === 'non_field_errors') {
        (errors as []).forEach((error: string) => {
          notificationStore.add({ description: error });
        });
      } else {
        (errors as []).forEach((error: string) => {
          notificationStore.add({ description: `${key}: ${error}` });
        });
      }
    }
  };

  const handleSelectProduct = (e: any, value: string | null) => {
    if (value) {
      setFormData({ selected_product: value })
    }
  }

  const handleONAInputChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setFormData({ ona_range: { minValue: newValue[0], maxValue: newValue[1] } })
    }
  };

  const handleSunElevationInputChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setFormData({ sun_elevation_range: { minValue: newValue[0], maxValue: newValue[1] } })
    }
  }; 

  // Weather Forecast

  function matchForecasts(
    opportunities: Opportunity[],
    weather: CloudForecastResponse,
    maxDiffInHours: number = 2
  ): any {
    const maxDiffInMillis = maxDiffInHours * 60 * 60 * 1000;
    const updatedOpportunities = opportunities.map(opportunity => {
      const startTime = new Date(opportunity.start).getTime();
  
      // Find the closest weather forecast
      const closestForecast = weather.samples.reduce<{ forecast: any | null; diff: number }>(
        (closest, forecast) => {
          const forecastTime = new Date(forecast.datetime).getTime();
          const diff = Math.abs(startTime - forecastTime);
  
          if (diff < closest.diff && diff <= maxDiffInMillis) {
            return { forecast, diff };
          }
  
          return closest;
        },
        { forecast: null, diff: Infinity }
      );
  
      return {
        ...opportunity,
        forecast: closestForecast.forecast
      };
    });
    return updatedOpportunities;
  }

  const requestWeatherForecast = async (opportunities: Opportunity[], target: any) => {
    const token = await userStore.auth0Client?.getTokenSilently();
    const request: CloudForecastRequest = {target: target.coordinates};
    getWeatherForecast(
      request, 
      {token: token}, 
      (response: CloudForecastResponse) => {
        if (!!response) {
          const improved_opps = matchForecasts(
            opportunities, 
            response,
          );
          setOppsData(improved_opps);
        }
      },  
      (error: any) => {
        console.log(`Error fetching forecast data ${error}`)
      }
    )
  }

  const startPolling = async (type: "can-add" | "opportunities") => {

    const token = await userStore.auth0Client?.getTokenSilently();

    if (!formData.selected_product) return;
    if (!formData.start || !formData.end || !formData.target) return;

    if (formData.start && formData.end && formData.target) {

      if (type === "can-add") {
        canAddOrder(
          token,
          productList[formData.selected_product]?.tasks.capture.product_id,
          formData.start.toISOString(),
          formData.end.toISOString(),
          [formData.target.coordinates[1], formData.target.coordinates[0]],
          {
            min_ona: formData.ona_range?.minValue,
            max_ona: formData.ona_range?.maxValue,
            min_sun_elevation: formData.sun_elevation_range?.minValue,
            max_sun_elevation: formData.sun_elevation_range?.maxValue,
            priority: productList[formData.selected_product]?.tasks.capture.priority
          },
          (response: any) => {
            setAnalysisData((prev) => ({ ...prev, hash: response.data, status: 'pending' }));
          },
          (response: any) => {
            notificationStore.add({ description: "There was an error calculating opportunities.: " + response.data });
            setAnalysisData({ hash: null, status: 'idle'});
          }
        )
      } else if (type === "opportunities") {
        getOpportunities(
          token,
          productList[formData.selected_product]?.tasks.capture.product_id,
          formData.start.toISOString(),
          formData.end.toISOString(),
          [formData.target.coordinates[1], formData.target.coordinates[0]],
          {
            min_ona: formData.ona_range?.minValue,
            max_ona: formData.ona_range?.maxValue,
            min_sun_elevation: formData.sun_elevation_range?.minValue,
            max_sun_elevation: formData.sun_elevation_range?.maxValue,
          },
          (response: any) => {
            setAnalysisData((prev) => ({ ...prev, hash: response.data, status: 'pending' }));
          },
          (response: any) => {
            notificationStore.add({ description: "There was an error calculating opportunities.: " + response.data });
            setAnalysisData({ hash: null, status: 'idle' });
          }
        )
      }

    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (analysisData.status === 'pending') {
      intervalId = setInterval(async () => {

        try {
          const token = await userStore.auth0Client?.getTokenSilently();
          if (analysisData.hash) {

            getAnalysis(token, analysisData.hash,
              (analysis: AnalysisResult) => {

                if (analysis.status === StatusEnum.SUCCESS) {

                  clearInterval(intervalId);

                  if (formData.selected_product === "rush" && analysis.analysis_result.planned_captures.length == 0) {
                    setAnalysisData({ ...analysisData, status: 'empty'});

                  } else if (formData.selected_product === "rush" && analysis.analysis_result.planned_captures.length > 0) {
                    setAnalysisData({ ...analysisData, status: 'finished'});
                    requestWeatherForecast(
                      analysis.analysis_result.planned_captures as Opportunity[], 
                      formData.target,
                    );

                  } else if (formData.selected_product === "standard" && analysis.status === StatusEnum.SUCCESS) {
                    setAnalysisData({ ...analysisData, status: 'finished'});
                    setStandardIsFeasible(analysis.analysis_result.length > 0)
                  }

                  //setOppsData(analysis.analysis_result.planned_captures);                  
                } else if (analysis.status === StatusEnum.PENDING) {
                  setAnalysisData({ ...analysisData, status: 'pending' });
                } else {
                  clearInterval(intervalId);
                  setAnalysisData({ ...analysisData, status: 'finished' });
                }
              },
              () => { },
            )
          }
        } catch (error) {
          clearInterval(intervalId);
          setAnalysisData({ hash: null, status: 'error' });
          console.error('Error polling:', error);
        }
      }, 1000); // Poll every 5 seconds
    }

    return () => clearInterval(intervalId);
  }, [analysisData.status, analysisData.hash]);


  useEffect(() => {
    if (formData.selected_product) {
      const fixedParams = productList[formData.selected_product]?.fixedParameters;
      const configurableParams = productList[formData.selected_product]?.configurableParameters;

      if (fixedParams) {  // Check if fixedParams exists
        for (const key in fixedParams) {

          if (key == "collection_window") {
            setFormData({
              start: addDurationToDate(new Date(), (fixedParams[key] as CollectionWindowType).startTimeOffset),
              end: addDurationToDate(new Date(), (fixedParams[key] as CollectionWindowType).endTimeOffset),
            }
            )
          }
        }
      }

      if (configurableParams) {
        for (const key in configurableParams) {
          if (key == "collection_window") {
            setFormData({
              start: addDurationToDate(new Date(), (configurableParams[key] as CollectionWindowType).startTimeOffset),
              end: addDurationToDate(new Date(), (configurableParams[key] as CollectionWindowType).endTimeOffset),
            }
            )
          } else if (key == "ona") {
            setFormData({
              ona_range: {
                minValue: (configurableParams[key] as RangeType).standard_range[0],
                maxValue: (configurableParams[key] as RangeType).standard_range[1],

              }
            })
          } else if (key == "sun_elevation") {
            setFormData({
              sun_elevation_range: {
                minValue: (configurableParams[key] as RangeType).standard_range[0],
                maxValue: (configurableParams[key] as RangeType).standard_range[1],
              }
            })
          }
        }
      }
    }
  }, [formData.selected_product]);

  // XXX: We cannot call goToProjects from the callback of the task submitting request, 
  // because if not the states are not updated.
  useEffect(() => {
    const goToProjects = () => {
      const path = '/projects';
      navigate(path);
    };
    if (triggerNavigation) {
      goToProjects();
      setTriggerNavigation(false);
    }
  }, [triggerNavigation]);

  /*
  useEffect(() => {
    console.log("Analysis data update: ", analysisData);
  }, [analysisData]);
  
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    console.log(analysisData);
  }, [analysisData]);
  

  useEffect(() => {
    console.log("valid inputs:", validInput)
  }, [validInput]);

  */



  /* Helpoer consts to make the HTML part more readable */
  const configurables = formData.selected_product ? productList[formData.selected_product].configurableParameters : undefined;
  const onaConfigurable = formData.selected_product ? productList[formData.selected_product].configurableParameters?.ona as RangeType : undefined;


  const onaRange = onaConfigurable ? {
    lower: onaConfigurable?.standard_range[0],
    upper: onaConfigurable?.standard_range[1],
  } : { lower: 0, upper: 25 }


  const onaExtendedRange = onaConfigurable?.extended_range ? {
    lower: onaConfigurable.extended_range[0],
    upper: onaConfigurable.extended_range[1],
  }
    : undefined;

  const sunConfigurable = formData.selected_product ? productList[formData.selected_product].configurableParameters?.sun_elevation as RangeType : undefined;
  const sunRange = sunConfigurable ? {
    lower: sunConfigurable?.standard_range[0],
    upper: sunConfigurable?.standard_range[1],
  } : { lower: 15, upper: 90 };

  const sunExtendedRange = sunConfigurable?.extended_range ? {
    lower: sunConfigurable.extended_range[0],
    upper: sunConfigurable.extended_range[1],
  }
    : undefined;

  const configurableCollectionWindow = formData.selected_product ? productList[formData.selected_product].configurableParameters?.collection_window as CollectionWindowType : undefined;
  const now = new Date();

  const isFormEditable = (analysisData.status == 'idle' || analysisData.status == 'error')

  return (
    <Layout>
      <Wrapper>
        <Sidebar>
          <FormTitle>Point of interest tasking</FormTitle>

          <Form method="POST">
            <FormGroup>
              <FormSubtitle>Task parameters</FormSubtitle>
              <Grid container spacing={3} width={"100%"}>

                <Grid container spacing={1} width={"100%"}>
                  <Grid size={10}>
                    <Autocomplete
                      size="small"
                      disabled={!isFormEditable}
                      disablePortal
                      value={formData.project_name}
                      options={taskStore.projects?.length > 0 ? taskStore.projects?.map((item: Project) => {
                        return { label: item.name, id: item.name };
                      }) : []}
                      noOptionsText="Loading available projects..."
                      sx={{ width: "100%", height: "50px" }}
                      renderInput={(params) =>
                        <TextField {...params}
                          label="Project name"
                          required
                          error={!validInput.project_name}
                          helperText={!validInput.project_name ? "This field is requierd" : ""}
                        />
                      }
                      onChange={(event: any, newValue: any | null) => {
                        if (!!newValue) {
                          setValidInput({ project_name: true });
                          setFormData({ project_name: newValue?.id });
                        } else {
                          setValidInput({ project_name: false });
                          setFormData({ project_name: undefined });
                        }
                      }}
                    />
                  </Grid>
                  <Grid display="flex" size={2} alignContent={"center"}>
                    <MuiButton
                      size='small'
                      disabled={!isFormEditable}
                      sx={{ maxHeight: '40px', }}
                      onClick={() => setShowModal({ newProject: true })}
                    >
                      + new
                    </MuiButton>
                  </Grid>
                </Grid>

                <Grid sx={{ "width": "100%" }}>
                  <TextField
                    sx={{ "width": "100%" }}
                    required
                    name='task_name'
                    label="Task name"
                    disabled={!isFormEditable}
                    size='small'
                    value={formData.task_name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValidInput({ task_name: e.target.validity.valid })
                      setFormData({ task_name: e.currentTarget.value })
                    }}
                    InputLabelProps={{ shrink: formData.task_name !== "" }}
                    error={!validInput.task_name}
                    helperText={!validInput.task_name ? "This field is requierd" : ""}
                  />
                </Grid>

                <ToggleButtonGroup
                  fullWidth
                  color="primary"
                  value={formData.selected_product}
                  exclusive
                  disabled={!isFormEditable}
                  onChange={handleSelectProduct}
                  //onChange={}
                  aria-label="Platform"
                >
                  {Object.entries(productList).map(([key, config]: [string, ProductConfig]) => (
                    <ToggleButton key={key} value={key}>
                      {config.tagline}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>


                {!!formData.selected_product && !!productList[formData.selected_product].configurableParameters &&
                  <Grid sx={{ "width": "100%" }}>

                    {!!formData.selected_product && (productList[formData.selected_product].configurableParameters?.target as TargetType).type === 'POI' && !(productList[formData.selected_product].configurableParameters?.target as TargetType).multiple &&
                      <FormGroup>
                        <TextField
                          required
                          ref={inputRef}
                          sx={{ "width": "100%" }}
                          name="lat_lng"
                          label="Target coordinates"
                          disabled={!isFormEditable}
                          size='small'
                          helperText={validInput.target ? 'Hint: Use the map or introduce the coordinates manually.' : "Invalid coordinates. Please check your input"}
                          value={coordsInput ? coordsInput : ''}
                          InputLabelProps={{ shrink: !!coordsInput }}

                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCoordsInput(e.currentTarget.value);
                          }}

                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            const result = parseCoordinates(e.currentTarget.value);
                            if (!!result) {
                              const [lat, lng] = result;
                              if (isTargetValid([lat, lng])) {
                                setPoint(lat, lng);
                                setValidInput({ target: true });

                              } else {
                                setValidInput({ target: false });
                                setFormData({ target: { type: 'Point', coordinates: [] } })
                                notificationStore.add({ description: "Point outside geofence. Please review input" });
                              }
                            } else {
                              setValidInput({ target: false });
                              setFormData({ target: { type: 'Point', coordinates: [] } })
                              notificationStore.add({ description: "Invalid coordinates. Please review input" });
                            }
                          }
                          }
                          error={!validInput.target}
                        />
                      </FormGroup>
                    }

                    {!!configurableCollectionWindow &&
                      <FormGroup>
                        <DateRange
                          disabled={!isFormEditable}
                          className="form-input"
                          clear={formData.start === null}
                          name="taskingDates"
                          startValue={formData.start}
                          endValue={formData.end}
                          onChangeStart={(startDate: Date) => {
                            setFormData({ start: startDate })
                            setValidInput({ start: true });
                          }
                          }
                          onChangeEnd={(endDate: Date) => {
                            setFormData({ end: endDate })
                            setValidInput({ end: true });
                          }
                          }
                          startLabel="Start Date"
                          endLabel="End Date"

                          startMin={addDurationToDate(now, configurableCollectionWindow.startTimeOffset)}
                          maxStartDate={addDurationToDate(now, configurableCollectionWindow.endTimeOffset)}
                          maxEndDate={addDurationToDate(now, configurableCollectionWindow.endTimeOffset)}

                          //daysAfterStart={14}
                          monthsShown={1}

                          startPlacement="top-start"
                          endPlacement="top-end"
                          start_error={!validInput.start && !!formData.start}
                          end_error={!validInput.end && !!formData.end}
                        />

                        <Input
                          className="form-input half"
                          //helperText={minStartTime !== '00:00' ? `Start time must be ${minStartTime} or later` : undefined}
                          label="Start time"
                          max={"23:59"}
                          //min={minStartTime}
                          name="startHour"
                          type="time"
                        //value={formData.start ? format(formData.start, 'HH:mm') : "00:00"}
                        //onChange={(e: any)=>{setFormData({ start: `${format(formData.start, 'yyyy-MM-dd')}T${e.target.value}:00Z`}})}
                        /*onBlur=
                          //setValidStartTime(!!e.target.value)
                          setStartTime(!!e.target.value ? e.target.value : startTime)
                        }}*/
                        />
                        <Input
                          className="form-input half"
                          //helperText={minEndTime !== '00:00' ? `End time must be ${minEndTime} or later` : undefined}
                          label="End time"
                          max={"23:59"}
                          //min={minEndTime}
                          //value={formData.end ? format(formData.end, 'HH:mm') : "00:00"}
                          name="endHour"
                          type="time"
                        //value={formData.end}
                        /*onBlur={e => {
                          //setValidEndTime(!!e.target.value)
                          setEndTime(!!e.target.value ? e.target.value : endTime)
                        }}*/
                        />

                        {/* Conditionally render error messages */}
                        {!validInput.start && !!formData.start && <p style={{ color: 'red' }}>Start date is invalid.</p>}
                        {!validInput.end && !!formData.end && <p style={{ color: 'red' }}>End date is invalid.</p>}
                      </FormGroup>
                    }
                  </Grid>
                }

                {!!configurables && <Grid sx={{ "width": "100%" }}>
                  <Collapsable title={'Advanced constraints'} icon={mdiTune}>
                    {!!configurables && Object.keys(configurables).includes("ona") &&
                      <>Off nadir angle:
                        <RangeSlider
                          values={formData.ona_range || { minValue: 0, maxValue: 25 }}
                          id={'ona_range'}
                          warning={<Typography fontSize={12}>Warning: one of the ends of the ONA range exceeds the standard limit.
                            This might affect the quality of the delivered imagery. &nbsp;
                            <Link href="#" onClick={() => setFormData({ ona_range: { minValue: 0, maxValue: 25 } })}>Reset defaults</Link>
                          </Typography>}
                          range={onaRange}
                          extendedRange={onaExtendedRange}
                          unit={"°"}
                          disabled={!isFormEditable}
                          onChange={handleONAInputChange}
                          leftIcon={<SatelliteAltOutlined sx={{ transform: "rotate(45deg)" }} />}
                          rightIcon={<SatelliteAltOutlined sx={{ transform: "rotate(0deg)" }} />}
                        />
                      </>}

                    {!!configurables && Object.keys(configurables).includes("sun_elevation") &&
                      <>Sun elevation angle:
                        <RangeSlider
                          values={formData.sun_elevation_range || { minValue: 15, maxValue: 90 }}
                          id={'sun_elevation'}
                          warning={<Typography fontSize={14}>Warning: one of the ends of the Sun Elevation range exceeds the standard limit.
                            This might affect the quality of the delivered imagery. &nbsp;
                            <Link href="#" onClick={() => setFormData({ sun_elevation_range: { minValue: 15, maxValue: 90 } })}>Reset defaults</Link>
                          </Typography>}
                          range={sunRange}
                          extendedRange={sunExtendedRange}
                          unit={"°"}
                          disabled={!isFormEditable}
                          onChange={handleSunElevationInputChange}
                          leftIcon={<Icon path={mdiWeatherSunsetUp} size={1} />}
                          rightIcon={<Icon path={mdiWeatherSunny} size={1} />}

                        /></>}
                  </Collapsable>
                </Grid>
                }
              </Grid>

            </FormGroup>

            <FormGroup>

              {(analysisData.status === "pending" || analysisData.status === "finished" || analysisData.status === "error" || analysisData.status === "empty") &&

                <FormGroup>
                  <FormSubtitle> Feasibility analysis </FormSubtitle>

                  {(analysisData.status === "pending" || !oppsData) &&
                    <>
                      <div style={{ width: "100%" }}>
                        <LinearProgress key={"loading"} />
                      </div>
                      <FormText>Analyzing...</FormText>
                      <FormText>
                        Currently there is a feasibilty study in progress and the request cannot be modified. The process can take up to several minutes. You can safely browse another window and come back at any time.
                      </FormText>
                    </>
                  }

                  {analysisData.status === "error" &&
                    <FormText style={{ color: "darkred", "fontSize": '16px'}}>
                      {"There was an error calculating opportunities. Please try again"}
                    </FormText>
                  }

                  {analysisData.status === "empty" &&
                    <FormText style={{ color: "darkred", "fontSize": '16px'}}>
                      {"No opportunities where found. Please review the constraints and try again"}
                    </FormText>
                  }

                  <Grid2 container spacing={1} sx={{ width: "100%" }}>
                    {formData.selected_product === "rush" && analysisData.status === "finished" &&
                      <>
                        <FormText>Select a capture from the table. </FormText>
                        <OppsTable
                          oppsData={oppsData}
                          headCells={headCells}
                          onCaptureSelect={(capture: any) => { setSelectedOpportunity(oppsData.find((item: any) => item.start === capture?.start)) }}
                        />

                        <MuiButton
                          variant='contained'
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setShowModal({ preview: true });
                          }}
                          disabled={!selectedOpportunity}
                        >
                          Create task
                        </MuiButton>
                      </>
                    }

                    {formData.selected_product === "standard" && analysisData.status === "finished" &&
                      <>
                        {(standardIsFeasible) ? 
                          <FormText style={{color: "darkgreen"}}>
                            The requested task is feasible and subject to availability at the moment of placing the order.
                          </FormText>
                        : 
                        <FormText style={{color: "darkred"}}>
                          The requested task is not feasible based on the current parameters. Please adjust your criteria or try again later.
                        </FormText>
                        }

                        <MuiButton
                          variant='contained'
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setShowModal({ preview: true });
                          }}
                          disabled={!standardIsFeasible}
                        >
                          Create task
                        </MuiButton>
                      </>
                    }

                    <Grid2 sx={{ width: "100%" }} size={12}>
                      <MuiButton
                        sx={{ width: "100%" }}
                        variant="outlined"
                        onClick={() => {
                          setAnalysisData({ hash: null, status: 'idle'});
                        }}
                      >
                        Cancel analysis
                      </MuiButton>
                    </Grid2>

                  </Grid2>
                </FormGroup>}
            </FormGroup>


            {(isFormEditable) && !!formData.selected_product && productList[formData.selected_product].configurableParameters?.opportunity &&
              <Grid container spacing={0} width={"100%"}>

                <Grid size={12} sx={{ width: "100%" }}>
                  <MuiButton
                    sx={{ marginTop: "10px", width: "100%" }}
                    variant="contained"
                    disabled={
                      !Object.values(validInput).every(isValid => isValid) ||
                      !formData.task_name ||
                      !formData.project_name ||
                      !formData.start || !formData.end ||
                      !formData.target ||
                      !formData.sun_elevation_range || !formData.ona_range
                    }
                    onClick={async () => {
                      setAnalysisData({ hash: null, status: 'pending'}); // initial pending so the form is deactivated
                      startPolling("can-add");
                    }}
                  >
                    Calculate opportunities
                  </MuiButton>
                </Grid>
              </Grid>
            }

            {(isFormEditable) && !!formData.selected_product && !productList[formData.selected_product].configurableParameters?.opportunity &&
              <Grid size={12} sx={{ width: "100%" }}>
                <MuiButton
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="contained"
                  disabled={
                    !Object.values(validInput).every(isValid => isValid) ||
                    !formData.task_name ||
                    !formData.project_name ||
                    !formData.start || !formData.end ||
                    !formData.target ||
                    !formData.sun_elevation_range || !formData.ona_range
                  }

                  onClick={async () => {
                    setAnalysisData({ hash: null, status: 'pending'}); // initial pending so the form is deactivated
                    startPolling("opportunities");
                  }}
                >
                  Validate Task
                </MuiButton>
              </Grid>
            }

            {isFormEditable &&
              <Grid size={12} sx={{ width: "100%" }}>
                <MuiButton
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                  onClick={() => {
                    setFormData(emptyForm);
                    setCoordsInput(undefined);
                    setAnalysisData({ hash: null, status: 'idle'});
                  }}
                >
                  Reset form
                </MuiButton>
              </Grid>
            }
          </Form>

        </Sidebar>

        <MapContainer>
          {!userStore.geofence ? (
            <LoadingWrapper>
              <LoadingIcon />
            </LoadingWrapper>
          ) : (

            <Map
              dragRotate={false}
              keyboard={false}
              touchZoomRotate={false}
              maxZoom={18}
              ref={ref => setMapRef(ref)}
              style={{ width: '100%', height: '100%' }}
              mapStyle="https://tiles.stadiamaps.com/styles/alidade_smooth.json"
              interactive
              initialViewState={{
                longitude: 0,
                latitude: 0,
                zoom: 1.75
              }}
              interactiveLayerIds={['tileCardLayer']}
              onMouseMove={(e) => {
                setMoveEvent(e.lngLat.toArray());
              }}
              onMouseOut={(e) => {
                setMoveEvent(null);
              }}
              onClick={(e: any) => {
                if (isFormEditable && !!formData.selected_product && (productList[formData.selected_product].configurableParameters?.target as TargetType).type === 'POI' && !(productList[formData.selected_product].configurableParameters?.target as TargetType).multiple) {
                  setValidInput({ target: true });
                  setPoint(e.lngLat.lat, e.lngLat.lng)
                }
              }}
            >

              <ScaleControl position='bottom-right' />
              <GeolocateControl position="top-right" />
              <FullscreenControl position="top-right" />
              <NavigationControl position="top-right" />

              <GeocoderControl
                position="top-left"
                onChange={(feature: any) => { if (!!feature.geometry.coordinates && !!mapRef) mapRef.flyTo({ center: feature.geometry.coordinates, zoom: 12 }) }}
              />
              <InfoControl position="bottom-left"
                moveEvent={moveEvent}
                zoomLevel={mapRef?.getZoom()}
              />

              {!!formData.target?.coordinates[0] &&
                <Marker longitude={formData.target?.coordinates[1]} latitude={formData.target?.coordinates[0]}>

                </Marker>
              }
            </Map>

          )}
        </MapContainer>

      </Wrapper>
      {formData.selected_product === "rush" && showModal.preview && !!selectedOpportunity && formData.selected_product && (
        <TaskingOrderConfirmModal
          isShown={showModal.preview}
          onAccept={() => { requestTask() }}
          onClose={() => setShowModal({ preview: false })}
          body={{
            task_name: formData.task_name || "New Task",
            project_name: formData.project_name || "New project",
            product: productList[formData.selected_product]?.tasks.capture.product_id,
            product_text: productList[formData.selected_product]?.label,
            target: formData.target,
            start: buildTaskStart(selectedOpportunity.start),
            end: buildTaskEnd(selectedOpportunity.end),
            ona_range: buildOnaRange(selectedOpportunity.metadata.min_absolute_off_nadir, selectedOpportunity.metadata.max_absolute_off_nadir),
            sun_elevation_range: buildSunAngleRange(selectedOpportunity.metadata.min_sun_elevation, selectedOpportunity.metadata.max_sun_elevation),
            max_captures: 1, // not important, but TaskData requieres it
          }}
        />
      )
      }

      {formData.selected_product === "standard" && showModal.preview && formData.selected_product && (
        <TaskingOrderConfirmModal
          isShown={showModal.preview}
          onAccept={() => { requestTask() }}
          onClose={() => setShowModal({ preview: false })}
          body={{
            task_name: formData.task_name || "New Task",
            project_name: formData.project_name || "New project",
            product: productList[formData.selected_product]?.tasks.capture.product_id,
            product_text: productList[formData.selected_product]?.label,
            target: formData.target,
            start: formData.start ? formData.start.toISOString() : null,
            end: formData.end ? formData.end.toISOString() : null,
            max_captures: 1, // not important, but TaskData requieres it
          }}
        />
      )
      }

      {
        showModal.newProject && <ProjectModal
          isShown={showModal.newProject}
          onClose={() => setShowModal({ newProject: false })}
          onClick={name => {
            taskStore.addProject({ name });
            setFormData({ project_name: name });
          }}
        />
      }

    </Layout >
  );
});

import { Box, Slider, Stack, Theme, Typography } from "@mui/material";
import { SxProps, useTheme } from "@mui/system";
import React, { useEffect } from "react";

interface RangSliderProps {
    values: { minValue: number, maxValue: number };
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    disabled?: boolean;
    id: string;
    warning?: React.ReactNode | string;
    onChange?: (event: Event, newValue: number | number[]) => void;
    range: { lower: number, upper: number };
    extendedRange?: { lower: number, upper: number };
    unit?: string;
    title?: string;
}

export const RangeSlider: React.FC<RangSliderProps> = ({
    values,
    leftIcon,
    rightIcon,
    disabled,
    id,
    onChange,
    warning,
    range,
    extendedRange,
    unit,
    title
}) => {

    const theme = useTheme();

    const isValidRange = (range: { lower: number; upper: number }) =>
        range.lower >= 0 && range.upper >= 0 && range.upper > range.lower;

    const isValidExtendedRange = (extendedRange: { lower: number; upper: number }) =>
        extendedRange.lower >= 0 && extendedRange.upper >= 0 &&
        extendedRange.upper > extendedRange.lower &&
        !(extendedRange.lower > range.lower && extendedRange.upper < range.upper)
        ;


    useEffect(() => {
        if (!isValidRange(range) || (extendedRange && !isValidExtendedRange(extendedRange))) {
            throw new Error("Invalid range or extendedRange props");
        }
    }, [range, extendedRange]);


    function isWithinExtendedRange(value: number): boolean {
        if (!!extendedRange) {            
                        
            // Case 1: Extended range completely contains standard range
            if ((extendedRange.lower < range.lower) && (extendedRange.upper > range.upper)) {                                
                return value < range.lower || value > range.upper;
            }
            
            // Case 3: Extended range partially overlaps standard range (left side)
            else if (extendedRange.lower < range.lower && extendedRange.upper > range.lower && extendedRange.upper <= range.upper) {
                return value < range.lower;
            }            

            // Case 4: Extended range partially overlaps standard range (right side)
            else if (extendedRange.lower >= range.lower && extendedRange.lower < range.upper && extendedRange.upper > range.upper) {
                return value > range.upper;
            }

            return false
        } else {
            return false;
        }
    }

    return (
        <Box sx={{ width: "100%", marginBottom: "30px" }}>
            {!!title && <p>{title}</p>}
            <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                {leftIcon}
                <Slider
                    sx={extendedRange ? {

                        "& .MuiSlider-rail": {
                            background: `linear-gradient(to right, 
                                #ff9a3c 0%, 
                                #ff9a3c ${(Math.max(range.lower, extendedRange.lower) / extendedRange.upper) * 100}%, 
                                ${theme.palette.primary.main} ${(Math.max(range.lower, extendedRange.lower) / extendedRange.upper) * 100}%, 
                                ${theme.palette.primary.main} ${(Math.min(range.upper, extendedRange.upper) / extendedRange.upper) * 100}%, 
                                #ff9a3c ${(Math.min(range.upper, extendedRange.upper) / extendedRange.upper) * 100}%, 
                                #ff9a3c 100%)`,
                        },
                        "& .MuiSlider-track": {
                            background: `${theme.palette.primary.main}`,
                        },
                        "& .MuiSlider-thumb": {
                            "&[data-index='1']": { backgroundColor: isWithinExtendedRange(values.maxValue) ? "#ff9a3c" : theme.palette.primary.main},
                            "&[data-index='0']": { backgroundColor: isWithinExtendedRange(values.minValue) ? "#ff9a3c" : theme.palette.primary.main},
                        },
                    }
                        : {
                            "& .MuiSlider-rail": {
                                background: `${theme.palette.primary.main}`
                            }
                        }}

                    disabled={disabled}
                    id={id}
                    getAriaLabel={() => 'Off nadir range'}
                    value={[values.minValue, values.maxValue]}
                    onChange={(event: Event, newValue: number | number[]) => {
                        if (onChange) {
                            onChange(event, newValue)
                        }
                    }}
                    valueLabelDisplay="auto"
                    size='small'
                    min={!!extendedRange ? Math.min(range.lower, extendedRange.lower) : range.lower}
                    max={!!extendedRange ? Math.max(range.upper, extendedRange.upper) : range.upper}
                    defaultValue={[range.lower, range.upper]}
                    marks={[
                        {
                            value: values.minValue,
                            label: `${values.minValue} ${unit}`
                        },
                        {
                            value: values.maxValue,
                            label: `${values.maxValue} ${unit}`
                        }
                    ]}
                    step={1}
                />
                {rightIcon}
            </Stack>


            {(isWithinExtendedRange(values.maxValue) || isWithinExtendedRange(values.minValue)) &&
                <div style={{ paddingTop: "20px" }}>                    
                    {warning}                
                </div>
            }
        </Box>
    )
}

export default RangeSlider;
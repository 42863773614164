import React from 'react'
import Map, { Marker } from 'react-map-gl/maplibre';
import { Button } from 'components/button'
import {
  CrossIcon,
  LoadingIcon,
  PinIconModal,
  SatelliteIcon,
  ArrowIcon,
  CalendarIconModal
} from 'components/icons'
import { BaseModal } from '../basemodal'

import { dateFormater, coordinatesFormater } from 'config/helpers'
import { TaskData } from 'typings'
import Icon from '@mdi/react';
import {
  ModalDiv,
  ModalInfo,
  RowText,
  ProjectText,
  TaskText,
  Label,
  ButtonWrapper,
  Divider,
  MapContainer
} from './taskingorderconfirmmodal.styles';
import { SatelliteAltOutlined } from '@mui/icons-material';
import { mdiWeatherSunsetUp } from '@mdi/js';


interface Props {
  className?: string;
  body: TaskData;
  isShown: boolean;
  onClose: () => void;
  onAccept: () => void;
  isArea?: boolean;
  targetArea?: number;
}

export const TaskingOrderConfirmModal = ({
  className,
  isShown,
  onClose,
  body,
  onAccept,
}: Props) => {
  const [status, setStatus] = React.useState('');

  const customAccept = () => {
    setStatus('loading');
    onAccept();
  };

  
  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <ModalDiv className={className}>
        <MapContainer>
          <Map
            style={{ width: '100%', height: '100%' }}
            mapStyle="https://tiles.stadiamaps.com/styles/alidade_smooth.json"
            interactive={false}
            initialViewState={{
              longitude: body.target.coordinates[1],
              latitude: body.target.coordinates[0],
              zoom: 6
            }}
          >
            <Marker longitude={body.target?.coordinates[1]} latitude={body.target?.coordinates[0]}>
            </Marker>
          </Map>
        </MapContainer>
        <ModalInfo>
          <CrossIcon onClick={onClose} className="cross--icon" />
          <RowText>
            <ProjectText>{body.project_name}</ProjectText>
          </RowText>
          <RowText>
            <TaskText>{body.task_name}</TaskText>
          </RowText>
          <RowText>
            <SatelliteIcon /> <Label>{body.product_text}</Label>
          </RowText>
          <Divider />

          <RowText>
            <PinIconModal />
            <Label>{coordinatesFormater(body.target.coordinates)}</Label>
          </RowText>

          {body.start && (
            <RowText>
              <CalendarIconModal />
              <Label>{dateFormater(body.start)}</Label>
              {!!body.end && (
                <>
                  <ArrowIcon className="arrow--icon" />
                  <Label>{dateFormater(body.end)}</Label>
                </>
              )}
            </RowText>
          )}
          
          {body.ona_range && (
            <RowText>              
              <SatelliteAltOutlined sx={{ transform: "rotate(0)" }} />                             
              <Label>{body.ona_range.minValue.toFixed(1)}° - {body.ona_range.maxValue.toFixed(1)}°</Label>              
            </RowText>
          )}

          {body.sun_elevation_range && (
            <RowText>              
              <Icon path={mdiWeatherSunsetUp} size={1} />
              <Label>{body.sun_elevation_range.minValue.toFixed(1)}° - {body.sun_elevation_range.maxValue.toFixed(1)}°</Label>              
            </RowText>
          )}


          <ButtonWrapper>
            <Button text="Cancel" onClick={onClose} secondary />
            <Button
              onClick={customAccept}
              disabled={status === 'loading'}
              icon={status === 'loading' ? <LoadingIcon /> : undefined}
              text={status !== 'loading' ? 'Place order' : undefined}
              rightIcon
            />
          </ButtonWrapper>
        </ModalInfo>
      </ModalDiv>
    </BaseModal>
  );
};

TaskingOrderConfirmModal.displayName = 'PreviewTargetModal';

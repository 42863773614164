import React, { useEffect, useState } from 'react';
import { getSatelliteGeneration } from './helpers';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, TableSortLabel } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './createTask.styles';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { Opportunity } from 'typings';

export interface HeadCell {
    id: string;
    label: string;
    tooltip: string | React.ReactNode | undefined;
    colspan?: number;
}

interface Props {
    oppsData: Opportunity[];
    headCells: HeadCell[];
    onCaptureSelect?: (capture: Opportunity | undefined) => void;
}

type Order = 'asc' | 'desc';


export const OppsTable: React.FC<Props> = ({ oppsData, headCells, onCaptureSelect }) => {

    const [orderBy, setOrderBy] = useState<keyof Opportunity>('start');
    const [order, setOrder] = useState<Order>('asc');
    const [visibleRows, setVisibleRows] = useState<Opportunity[]>([]);
    const [emptyRows, setEmptyRows] = useState<number>(0);

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }


    function getComparator<Key extends keyof Opportunity>(
        order: Order,
        orderBy: Key,
    ): (a: Opportunity, b: Opportunity) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const [page, setPage] = useState(0);
    const [selectedCaptureIndex, setSelectedCaptureIndex] = React.useState<number | undefined>(undefined);
    const rowsPerPage = 5;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty rows.


    useEffect(() => {
        setVisibleRows(
            [...oppsData]
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        )
        setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (oppsData?.length || 0)) : 0)
    }, [oppsData, order, orderBy, page, rowsPerPage]);


    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        if (selectedCaptureIndex === id) {
            setSelectedCaptureIndex(undefined)
            if (onCaptureSelect) {
                onCaptureSelect(undefined);
            }
        } else {
            setSelectedCaptureIndex(id);
            if (onCaptureSelect) {
                onCaptureSelect(oppsData.find(capture => capture.start === id.toString())!);
            }
        }

    };

    return (
        <>
            <TableContainer>
                <Table sx={{}} size="small" aria-label="Opportunities table">
                    <TableHead >
                        <TableRow>
                            {headCells.map((headCell) => (
                                <Tooltip title={headCell.tooltip} key={headCell.id} placement='top'>
                                    <StyledTableCell
                                        key={headCell.id}
                                        align={'center'}
                                        padding={'none'}
                                        colSpan={headCell.colspan ? headCell.colspan : 1}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => {
                                                setOrderBy(headCell.id as keyof Opportunity);
                                                if (order === 'asc') {
                                                    setOrder('desc');
                                                } else {
                                                    setOrder('asc');
                                                }
                                            }}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                </Tooltip>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row: any) => (
                            <StyledTableRow
                                hover
                                key={row.start}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={(event) => handleClick(event, row.start)}
                                aria-checked={selectedCaptureIndex === row.start}
                                tabIndex={-1}
                                selected={selectedCaptureIndex === row.start}
                            >
                                <StyledTableCell component="th" scope="row" align={'center'}>
                                    {format(new Date(row.start), 'MMM-dd')}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align={'center'}>
                                    {format(new Date(row.start), 'HH:mm:ss')}
                                </StyledTableCell>
                                <StyledTableCell align={'center'}>{getSatelliteGeneration(row.satellite)}</StyledTableCell>
                                <StyledTableCell align="center">{row.metadata.max_absolute_off_nadir.toFixed(1)}°</StyledTableCell>
                                <StyledTableCell align="center">{row.metadata.min_sun_elevation.toFixed(1)}°</StyledTableCell>
                                <StyledTableCell align="center">{(!!row.forecast) ? `${row.forecast.cloud_cover}%` : "-"}</StyledTableCell>

                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (33) * (emptyRows),
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={oppsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </>
    );
};


export type ISO8601Duration = string;

export type TargetType = { // The type of target allowed                                           
        type: 'POI' | 'AOI';
        multiple: boolean;
}
export type RangeType = {
    standard_range: [number, number];
    extended_range?: [number, number];
}

export type CollectionWindowType = {
    startTimeOffset: ISO8601Duration, // how far in time from the order the collection window can start
    endTimeOffset: ISO8601Duration, // how far in time from the order is the maximum capture time
    maxOrderSpan?: ISO8601Duration, // how much time between start and end is allowed. Optional         

};

export type CaptureTaskParameters = {
    product_id: string;                             // Is the Blackwing product ID, we need it now. We want to get rid of this.
    priority: number;                               // The Blackwing priority for the task. 
    max_simultaneous_captures: number;              // how many attemps
    max_captures: number;                           // max captures
}

export interface ProductConfig {
    sku: string,                                    // a unique code to identify this product
    label: string,                                  // A descriptive name, will be used in UI
    tagline: React.ReactNode | string,              // Used as subtitles when needed.    
    tasks: { [key: string]: CaptureTaskParameters};    // Others can be added with |    
    fixedParameters?: { [key: string]: RangeType | CollectionWindowType | boolean };                            // Properties that are fixed and must be enforced
    configurableParameters?: { [key: string]: TargetType | RangeType | CollectionWindowType | boolean }                      // Properties that are configurables and let to the user to decide.
}

export const productList: { [key: string]: ProductConfig } = {
    rush: {
        label: "Rush Tasking",
        tagline: <p> <b>Rush tasking</b> <br /> ASAP in the next 3 days </p>,
        sku: "rush-tasking",        
        tasks : {
            capture: {                
                    product_id: '169', 
                    priority: 13,    
                    max_captures: 1,  
                    max_simultaneous_captures: 1,          
            }
        },        
        fixedParameters: {
            collection_window: {
                startTimeOffset: "P0D",
                endTimeOffset: "P3D",
            },
        },
        configurableParameters:
        {
            target: {
                type: "POI",
                multiple: false,
            },
            opportunity: true,
            ona: {
                standard_range: [0, 25],
                extended_range: [0, 35]
            },
            sun_elevation: {
                standard_range: [15, 90],
                extended_range: [5, 90]
            },
        }
    },

    standard: {
        sku: "standard-tasking",
        tagline: <p> <b>Standard tasking</b> <br /> schedule the capture </p>,
        label: "Standard Tasking",
        
        tasks : {
            capture: {                
                    product_id: '169', 
                    priority: 15,    
                    max_captures: 1,
                    max_simultaneous_captures: 3,            
            }
        },
        fixedParameters: {
        },
        configurableParameters:
        {
            target: {
                type: "POI",
                multiple: false,
            },
            collection_window: {
                startTimeOffset: "P0D",
                endTimeOffset: "P8D", // FIXME: This should be 15, but the opps api fails with more than 8.
            },
            ona: {
                standard_range: [0, 25],
                extended_range: [0, 35]
            },
            sun_elevation: {
                standard_range: [15, 90],
                extended_range: [5, 90]
            },

        }
    }
}
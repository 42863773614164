import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js';
import { CollapsableIcon, CollapsableWrapper } from './collapsable.styles';


interface CollapsableProps {
  title: string;
  children?: React.ReactNode;
  icon?: string;
  initialOpen?: boolean;
}

export const Collapsable: React.FC<CollapsableProps> = ({ title, children, icon, initialOpen = false }) => {
  
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <CollapsableWrapper onClick={handleHeaderClick}>
        <Box display="flex" alignItems="center"> {/*This box helps with the spacing of the elements*/}
        {icon && <Icon
            path={icon}
            size={1}             
        />}
          <Typography ml={1}>{title}</Typography> {/*Add some margin here*/}
        </Box>
        <CollapsableIcon>
        <Icon
          path={!isOpen ? mdiChevronDoubleUp : mdiChevronDoubleDown}
          size={1}             
        />
        </CollapsableIcon>
      </CollapsableWrapper>
      {isOpen && <Box sx={{paddingTop: "20px"}}>{children}</Box>}
    </Box>
  );
};

export default Collapsable;

import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

export const CollapsableWrapper = styled.div`
  display: flex;  
  flex-direction: row;
  align-items: center; 
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

export const CollapsableIcon = styled.div`    
  margin-left: auto; 
  //transition: transform 0.3s ease-in-out;
  animation: ${rotate} 0.3s ease-in-out forwards;  
`;


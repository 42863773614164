import React, { createContext, useContext, useRef, useState } from 'react';
import { SearchTab } from 'views/archive/tabs/searchTab';
import { ResultsTab } from 'views/archive/tabs/resultsTab';
import { InfoTab } from 'views/archive/tabs/infoTab';
import { TabObject } from 'components/tabscontainer/tabscontainer';

interface TabContextProps {
  tabs: TabObject[];
  updateTabStatus: (targetLabel: string, enabled: boolean) => void;
  searchTabRef: React.RefObject<{
    triggerSearch: () => void;
    updateOutcomeID: (outcome_ids: Set<string>) => void
  }>;
}

const TabContext = createContext<TabContextProps | undefined>(undefined);


export const TabProvider: React.FC<{ children: React.ReactNode, }> = ({ children }) => {
  const searchTabRef = useRef<{ triggerSearch: () => void, updateOutcomeID: (outcome_ids: Set<string>) => void }>(null);

  const [tabs, setTabs] = useState<TabObject[]>([
    {
      label: 'Search',
      content: <SearchTab ref={searchTabRef} />,
      enabled: true
    },
    {
      label: 'Results',
      content: <ResultsTab />,
      enabled: false
    },
    {
      label: 'Details',
      content: <InfoTab />,
      enabled: false
    }
  ]);

  const updateTabStatus = (targetLabel: string, enabled: boolean) => {
    setTabs(prevTabs =>
      prevTabs.map(tab =>
        tab.label === targetLabel
          ? { ...tab, enabled }
          : tab
      )
    );
  };

  return (
    <TabContext.Provider value={{ tabs, updateTabStatus, searchTabRef }}>
      {children}
    </TabContext.Provider>
  );
};

// Custom hook to use TabContext
export const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error('useTabContext must be used within a TabProvider');
  }
  return context;
};
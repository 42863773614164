import styled from '@emotion/styled/macro';

export const ModalDiv = styled.div`
  width: 944px;
  height: auto;
  min-height: 472px;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

export const MapContainer = styled.div`
  width: 50%;
  align-items: center;
  background-color: #cad2d3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  position: relative;
  .pin--icon {
    width: 20px;
    transform: translateY(-50%);
    z-index: 2;
    position: relative;
  }
`;

export const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalInfo = styled.div`
  width: 50%;
  background-color: ${(props: any) => props.theme.neutral};
  display: flex;
  flex-direction: column;
  padding: 25px 25px 30px;
  position: relative;
  .cross--icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    fill: ${(props: any) => props.theme.primary};
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const RowText = styled.p`
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  white-space: nowrap;
  svg {
    width: 20px;
    height: 20px;
    stroke: ${(props: any) => props.theme.neutral80};
    &.arrow--icon {
      width: 14px;
      margin: 0 0 0 7px;
      transform: rotate(-90deg);
    }
  }
`;

export const ProjectText = styled.span`
  width: 100%;
  color: ${(props: any) => props.theme.neutral60};
  font-size: ${(props: any) => props.theme.sizes.small};
  font-weight: 500;
  white-space: nowrap;
`;

export const TaskText = styled.span`
  width: 100%;
  color: ${(props: any) => props.theme.neutral80};
  font-size: ${(props: any) => props.theme.sizes.large};
  font-weight: 500;
  white-space: nowrap;
`;


export const Label = styled.span`
  color: ${(props: any) => props.theme.neutral80};
  font-size: ${(props: any) => props.theme.sizes.small};
  font-weight: 500;
  margin-left: 8px;
  white-space: nowrap;

`;

export const ButtonWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  button {
    width: 47%;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props: any) => props.theme.neutral20};
  border: none;
  margin: 10px 0 25px;
`;

export const CloseButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

export const EmptyIcon = styled.span`
  width: 18px;
  height: 18px;
`;

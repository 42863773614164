import { useControl } from 'react-map-gl/maplibre';
import { useRef, useState, useCallback, useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { GeocodingApi } from '@stadiamaps/api';
import debounce from 'lodash.debounce';
import { parseCoordinates } from 'config/helpers';

export function GeocoderControl(props: { position: any; onChange?: (name: string) => void }) {
    const controlRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useRef<Root | null>(null);
    const [searchText, setSearchText] = useState<string | undefined>('');
    const [results, setResults] = useState<any[]>([]); // State to store autocomplete results
    const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility


    const api = new GeocodingApi();

    useControl(
        () => {
            const control = {
                onAdd: () => {
                    const container = document.createElement('div');
                    container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
                    controlRef.current = container;

                    // Create root for React 18 rendering
                    rootRef.current = createRoot(container);

                    // Initial render of the content inside the container
                    renderContent();
                    return container;
                },
                onRemove: () => {
                    if (rootRef.current) {
                        rootRef.current.unmount(); // Unmount React component when control is removed
                    }
                    controlRef.current?.parentNode?.removeChild(controlRef.current);
                    controlRef.current = null;
                    rootRef.current = null;
                }
            };
            return control;
        },
        { position: props.position }
    );

    // Debounced handler for input changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchText(query);
        setShowDropdown(query.length > 0);
        debouncedAutocomplete(query);
    };

    
    const handleSearch = async (query: string)  => {
        const result = parseCoordinates(query)
        if (result){
            const [lat, lng] = result; 
            // Ensure coordinates are returned in the correct order: [longitude, latitude]
            setResults([{
                properties: {
                    label: `Fly to coordinates`
                },
                geometry: { coordinates: [lng, lat] }
            }]);
        } else {            
            try {
                const res = await api.autocomplete({ text: query });
                setResults(res.features || []); // Store the features array in results                    
            } catch (error) {
                console.error("Geocoding API request failed:", error);
                setResults([]); // Clear results on error
            }
        }
    };
    
    // Debounced autocomplete function to avoid too many API requests
    const debouncedAutocomplete = useCallback(
        debounce(async (query: string) => {
            if (query !== "") {
                handleSearch(query);
            }
        }, 300), // Adjust debounce delay as needed
        []
    );


    // Handle selecting a result from the dropdown
    const handleResultClick = (feature: any) => {
        setSearchText("");
        setShowDropdown(false); // Close the dropdown
        setResults([]); // Clear the results

        if (props.onChange) {
            props.onChange(feature);
        }
    };


    // Function to render content inside the control's container using createRoot
    const renderContent = () => {
        if (rootRef.current) {

            rootRef.current.render(
                //@ts-ignore
                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                    <input
                        size={50}
                        key={"geocoderInput"}
                        type="text"
                        placeholder="Search for places or a coordinate"
                        value={searchText}
                        onChange={handleInputChange}
                    />

                    {showDropdown && results.length > 0 && (
                        <ul style={{
                            position: "absolute",

                            top: "100%",
                            left: 0,
                            width: "100%",
                            maxHeight: "200px",
                            overflowY: "auto",
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            listStyle: "none",
                            padding: "8px 0",
                            margin: 0,
                            zIndex: 999999999999
                        }}>
                            {results.map((feature) => (
                                <li
                                    key={feature.properties.gid}
                                    style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #eee"
                                    }}
                                    onClick={() => handleResultClick(feature)}
                                >
                                    {feature.properties.label} ({feature.geometry.coordinates.map((x: number) => x.toFixed(3)).join(", ")})
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            );
        }
    };

    useEffect(() => {
        renderContent();
    }, [searchText, showDropdown, results]);

    return null;
}